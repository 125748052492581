<template>
  <v-app>
    <v-navigation-drawer v-if="isAuthenticated" expand-on-hover app permanent style="top: 66px">
      <v-list v-for="(routerItem, index) in routerList" :key="index" nav dense>
        <v-list-item
            link
            :to="routerItem.path.replaceAll(/:(.*?)\?/gm, '').replace(/\/\//g, '/')"
        >
          <v-list-item-icon>
            <v-icon>{{ routerItem.menuIcon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ routerItem.menuTitle }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <AppBar v-if="isAuthenticated" :title="'TT ' + $t('printlist.title')">
      <template v-slot:app-bar>
        <v-spacer />
        Client IP: {{ clientIp }}
        <v-spacer />
        <div>
          <LocaleChanger class="mr-4" />
        </div>
        <div v-if="false">
          <v-menu offset-y bottom left>
            <template v-slot:activator="{ on }">
              <v-btn
                dark
                depressed
                v-on="on"
                color="transparent"
                min-width="30"
              >
                <v-icon small>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="isAuthenticated" @click="logout">
                <v-list-item-title>Log out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </AppBar>

    <v-main style="padding-left: 55px;">
      <Message />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    clientIp: "",
  }),
  mounted() {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((response) => {
        this.clientIp = response.ip;
      });
  },
  computed: {
    routerList() {
      return this.$router.options.routes.filter(item => {
        return !(item.menuTitle === undefined && item.menuIcon === undefined);
      });
    }
  },
  methods: {
    logout() {
      // TODO
    },
  },
};
</script>

<style>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.v-data-table td {
  height: 35px !important;
}

tr.v-data-table__selected {
  color: rgba(33, 150, 243, 0.87) !important;
  background-color: rgba(33, 150, 243, 0.12) !important;
}

.v-application a {
  text-decoration: none;
}
</style>
