import axios from "axios";
import router from "@/router";
import store from "@/store";
import EnvProvider from "jvjr-docker-env";

const apiClient = axios.create({
  baseURL: EnvProvider.value("ENTITY_SERVER_URL"),
  headers: { "Content-Language": localStorage.locale },
});

// JWT interceptor
if (EnvProvider.value("ENABLE_AUTH") === "1") {
  apiClient.interceptors.request.use(function (config) {
    if (config.url !== "user/verify") {
      config.headers["x-entity-user"] = store.getters["auth/entityUser"];
    }
    return config;
  });
}

// 404 redirect
apiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error && error.response.status === 404) {
      return router.push({
        name: "404",
      });
    }

    return Promise.reject(error.response.data);
  }
);

export class EntityService {
  /**
   * Verify user
   * @param username
   * @param password
   * @returns {Promise<void>}
   */
  static async verify(username, password) {
    const { data } = await apiClient.post(
      "user/verify",
      {
        username: username,
        password: password,
        locationCode: null,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return data;
  }
}
