import EnvProvider from "jvjr-docker-env";
import axios from "axios";

const stockOverview = {
  namespaced: true,
  state: {
    items: [],
    headers: [],
    tableItems: [],
    search: "",
    sku: "",
    singleItem: [],
    stockItems: [],
    sortByField: "location.code",
    sortDesc: false,
    itemsPerPage: 30,
    itemsPage: 1,
    sizes: [],
  },
  mutations: {
    SET_OPTIONS(state, data) {
      state.items = data;
    },
    SET_SIZES(state, data) {
      state.sizes = data;
    },
    SET_SKU(state, data) {
      state.sku = data;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_SEARCH(state, search) {
      state.search = search;
    },
    SET_SINGLEITEM(state, data) {
      state.singleItem = data;
    },
    SET_STOCK(state, data) {
      state.stockItems = data;
    },
  },
  actions: {
    fetchItems(context, refresh = true) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_LOADING", true);
        axios({
          method: "get",
          url:
            EnvProvider.value("ENTITY_SERVER_URL") +
            "skus?page=" +
            (context.state.itemsPage > 1 ? context.state.itemsPage : "1") +
            "&itemsPerPage=" +
            context.state.itemsPerPage +
            "&or[productnr]=" +
            context.state.search +
            "&or[ean]=" +
            context.state.search +
            "&or[upc]=" +
            context.state.search,
          headers: {
            accept: "application/json",
            "x-entity-user": context.rootGetters["auth/entityUser"],
          },
        }).then(
          (response) => {
            // Filter the array to having the results with the sku first
            let orderedArraySku;
            let orderedArrayNotSku;
            orderedArraySku = response.data.filter((result) =>
              result.sku.includes(context.state.search)
            );

            orderedArrayNotSku = response.data.filter(
              (result) => !result.sku.includes(context.state.search)
            );
            let finalArray = orderedArraySku.concat(orderedArrayNotSku);
            context.commit("SET_OPTIONS", finalArray);
            context.commit("SET_LOADING", false);
          },
          (err) => {
            context.commit("SET_LOADING", false);
            console.log(err);
          }
        );
      }
    },
    fetchProductInfoBySku(context, refresh = true) {
      if (refresh || context.state.singleItem.length === 0) {
        context.state.singleItem = [];
        context.state.sizes = [];
        axios({
          method: "get",
          url:
            EnvProvider.value("ENTITY_SERVER_URL") +
            "skus" +
            "/" +
            context.state.sku,
          headers: {
            accept: "application/json",
            "x-entity-user": context.rootGetters["auth/entityUser"],
          },
        }).then(
          (response) => {
            context.commit("SET_SINGLEITEM", response.data);
            context.commit("SET_SIZES", response.data.data.sizes);
            context.dispatch("fetchStockBySku");
            context.dispatch("setHeaders");
          },
          (err) => {
            console.log(err);
          }
        );
      }
    },
    setSku(context, sku) {
      return new Promise((resolve) => {
        context.commit("SET_SKU", sku);
        resolve();
      });
    },
    setSearch(context, search) {
      return new Promise((resolve) => {
        context.commit("SET_SEARCH", search);
        resolve();
      });
    },
    fetchStockBySku(context, refresh = true) {
      if (refresh || context.state.stockItems.length === 0) {
        context.state.stockItems = [];
        let sizesList = context.state.sizes;
        let urlSizes = "";
        for (let i = 0; i < sizesList.length; i++) {
          urlSizes += "&sku%5B%5D=" + sizesList[i].sku;
        }
        axios({
          method: "get",
          url:
            EnvProvider.value("INVENTORY_SERVER_URL") +
            "stocks" +
            "?page=" +
            (context.state.itemsPage > 1 ? context.state.itemsPage : "1") +
            "&itemsPerPage=500" +
            urlSizes +
            "&order[" +
            context.state.sortByField +
            "]=" +
            (context.state.sortDesc ? "desc" : "asc"),
          headers: {
            accept: "application/json",
            "x-entity-user": context.rootGetters["auth/entityUser"],
          },
        }).then(
          (response) => {
            context.commit("SET_STOCK", response.data);
            context.dispatch("setArray");
          },
          (err) => {
            console.log(err);
          }
        );
      }
    },
    setHeaders(context) {
      context.state.headers = [];
      context.state.headers.push({
        text: "Store",
        value: "name",
      });
      for (let i = 0; i < context.state.sizes.length; i++) {
        context.state.headers.push({
          text: context.state.sizes[i].size,
          value: context.state.sizes[i].sku,
        });
      }
    },
    setArray(context) {
      let tableItems = {};

      for (let i = 0; i < context.state.stockItems.length; i++) {
        if (
          // eslint-disable-next-line no-prototype-builtins
          tableItems.hasOwnProperty(context.state.stockItems[i].location.code)
        ) {
          tableItems[context.state.stockItems[i].location.code].skus.push(
            context.state.stockItems[i]
          );
        } else {
          tableItems[context.state.stockItems[i].location.code] = {
            name: context.state.stockItems[i].location.nameAndCode,
            skus: [context.state.stockItems[i]],
          };
        }
      }

      context.state.tableItems = Object.values(tableItems);
    },
  },
  getters: {
    skuRows: (state) => {
      let keys = [];
      state.headers.forEach((header) => {
        if (header.value === "name") {
          return;
        }
        keys.push(header.value);
      });
      return keys;
    },
  },
};
export default stockOverview;
