import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth";
import locationsOverview from "@/store/modules/locationsOverview";
import printListsOverview from "@/store/modules/printListsOverview";
import stock from "@/store/modules/stockOverview";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    locationsOverview,
    printListsOverview,
    stock,
  },
});
