import axios from "axios";
import router from "@/router";
import EnvProvider from "jvjr-docker-env";
import store from "@/store";

const apiClient = axios.create({
  baseURL: EnvProvider.value("PROCESSMANAGER_URL"),
});

// JWT interceptor
if (EnvProvider.value("ENABLE_AUTH") === "1") {
  apiClient.interceptors.request.use(function (config) {
    config.headers["x-entity-user"] = store.getters["auth/entityUser"];
    return config;
  });
}

// 404 redirect
apiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 404) {
      return router.push({
        name: "404",
      });
    }

    return Promise.reject(error.response.data);
  }
);

export class DownloadService {
  /**
   * abstract fetch items
   * @param url
   * @returns {Promise<{totalItems: number, items: []}>}
   */
  static async fetchDownload(url) {
    return apiClient.get(url, {
      responseType: "blob",
    });
  }

  /**
   * abstract fetch items
   * @param url
   * @param page
   * @param itemsPerPage
   * @param sortByField
   * @param sortDesc
   * @param search
   * @returns {Promise<{totalItems: number, items: []}>}
   */
  static async fetchItems(
    url,
    page = 1,
    itemsPerPage = 10,
    sortByField = "",
    sortDesc = false,
    search = []
  ) {
    let response = {
      items: [],
      totalItems: 0,
    };

    url += "?page=" + (page > 1 ? page : "1");
    url += "&itemsPerPage=" + itemsPerPage;
    if (sortByField !== "") {
      url += "&order[" + sortByField + "]=" + (sortDesc ? "desc" : "asc");
    }
    if (search.length > 0) {
      for (let i = 0; i < search.length; i++) {
        url += "&" + search[i].column + "=" + search[i].value;
      }
    }

    const { data } = await apiClient.get(url);
    response.items.length = 0; // clear
    for (let x = 0; x < data["hydra:member"].length; x++) {
      response.items.push(data["hydra:member"][x]);
    }
    response.totalItems = data["hydra:totalItems"];
    return response;
  }

  /**
   * Create print list resource
   * @param locationCode
   * @returns {Promise<any>}
   */
  static async downloadPrintList(locationCode) {
    return this.fetchDownload("aca_print?locationCode=" + locationCode);
  }

  /**
   * Fetch print list resource
   * @param listId
   * @returns {Promise<any>}
   */
  static async downloadListById(listId) {
    return this.fetchDownload("aca_print_list/" + listId);
  }

  /**
   * Fetch the print lists
   * @param locationCode
   * @returns {Promise<any>}
   */
  static async fetchPrintLists(
    page = 1,
    itemsPerPage = 25,
    sortByField = "",
    sortDesc = true,
    search = []
  ) {
    return this.fetchItems(
      "aca_print_lists",
      page,
      itemsPerPage,
      sortByField,
      sortDesc,
      search
    );
  }
}
