import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import store from "@/store";
import i18n from "@/plugins/i18n";
import "@/plugins/auth";
import "@/plugins/confirm";
import "@/plugins/tt";

import VueMdijs from "vue-mdijs";
import { mdiImageOff } from "@mdi/js";
Vue.config.productionTip = false;
VueMdijs.add({ mdiImageOff });
Vue.use(VueMdijs);
new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
