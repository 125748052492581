import { tableState, tableMutations, tableActions } from "@tt/vue-components";
import { DownloadService } from "@/services/DownloadService";

const printListsOverview = {
  namespaced: true,
  state: {
    ...tableState,
    location: null,
    sortByField: "printedDt",
    sortDesc: true,
    itemsPerPage: 25,
    itemsPage: 1,
    search: [],
  },
  mutations: {
    ...tableMutations,
    SET_ITEMS(state, data) {
      state.items = data.items;
      state.totalItems = data.totalItems;
    },
    SET_LOCATION(state, data) {
      state.location = data.location;
    },
  },
  actions: {
    ...tableActions,
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_ERROR", false);
        context.commit("SET_LOADING", true);
        DownloadService.fetchPrintLists(
          context.state.itemsPage,
          context.state.itemsPerPage,
          context.state.sortByField,
          context.state.sortDesc,
          context.state.search
        )
          .then((json) => {
            context.commit("SET_ITEMS", json);
            context.commit("SET_LOADING", false);
          })
          .catch((err) => {
            context.commit("SET_ERROR", true);
            context.commit("SET_LOADING", false);
            console.log(err);
          });
      }
    },
    setLocation(context, location) {
      context.commit("SET_LOCATION", location);
      context.commit("EMPTY_SEARCH");
      context.commit("ADD_SEARCH", {
        column: "locationCode",
        value: location.code,
      });
    },
  },
};

export default printListsOverview;
