const locationsOverview = {
  namespaced: true,
  state: {
    location: null,
    sortByField: "code",
  },
  mutations: {
    SET_LOCATION(state, location) {
      state.location = location;
    },
  },
  actions: {
    selectLocation(context, location) {
      context.commit("SET_LOCATION", location);
    },
  },
};

export default locationsOverview;
